import React from "react";

import PageHead from "@Components/PageHead";

function Clients() {
  return (
    <>
      <PageHead>
        <h2 className="head-title">
          <span>Clients</span>
        </h2>
      </PageHead>
    </>
  );
}

export default Clients;
